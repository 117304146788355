import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import { toast } from "react-toastify";
import {
  fetchConnectedNumber,
  fetchCooldown,
  fetchLastConnectedTime,
  fetchLastSpokenNumber,
  fetchMode,
  fetchQr,
  fetchStatus,
  postLogout,
  postPairingCodeMode,
  postQrCodeMode,
  postRestartService,
} from "../../services/whatsAppStatusApi";

function WhatsAppMenuModal({ onClose }) {
  const [data, setData] = useState({});
  const [qrImageUrl, setQrImageUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [serviceName, setServiceName] = useState("backend");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const intervalRef = useRef(null);

  const fetchData = async () => {
    setIsLoading(true);
    const newData = {};

    try {
      const connectedNumber = await fetchConnectedNumber();
      newData.connectedNumber = connectedNumber.connectedNumber || "No number connected";
    } catch (error) {
      newData.connectedNumber = "No number connected";
    }

    try {
      const lastConnectedTime = await fetchLastConnectedTime();
      newData.lastConnectedTime = lastConnectedTime.lastConnectedTime || "No last connected time available";
    } catch (error) {
      newData.lastConnectedTime = "No last connected time available";
    }

    try {
      const cooldown = await fetchCooldown();
      newData.cooldown = cooldown.cooldownMessage || "No cooldown message available";
    } catch (error) {
      newData.cooldown = "No cooldown message available";
    }

    try {
      const lastSpokenNumber = await fetchLastSpokenNumber();
      newData.lastSpokenNumber = lastSpokenNumber.lastSpokenNumber || "No last spoken number available";
    } catch (error) {
      newData.lastSpokenNumber = "No last spoken number available";
    }

    try {
      const mode = await fetchMode();
      newData.mode = mode.mode;
      newData.phoneNumber = mode.phoneNumber || "N/A";
    } catch (error) {
      newData.mode = "Error fetching data";
      newData.phoneNumber = "Error fetching data";
    }

    try {
      const status = await fetchStatus();
      newData.status = status.connectionStatus || "Unknown";
    } catch (error) {
      newData.status = "Error fetching data";
    }

    try {
      const qrBlob = await fetchQr();
      const qrImageUrl = URL.createObjectURL(qrBlob);
      setQrImageUrl(qrImageUrl);
    } catch (error) {
      toast.error("Error al obtener el código QR.");
      setQrImageUrl("");
    }

    setData(newData);
    setIsLoading(false);
  };

  const resetInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(fetchData, 10000);
  };

  const handleButtonAction = async (action) => {
    setIsButtonDisabled(true);
    toast.warning("Realizando petición...");
    try {
      await action();
      resetInterval();
    } catch (error) {
      console.error("Error realizando la acción:", error);
      toast.error("Error realizando la acción.");
    } finally {
      setIsButtonDisabled(false);
    }
  };

  const handleLogout = async () => {
    await handleButtonAction(postLogout);
    toast.success("Desconectado exitosamente");
  };

  const handlePairingCodeMode = async () => {
    await handleButtonAction(postPairingCodeMode);
    toast.success("Modo de código de emparejamiento activado");
  };

  const handleQrCodeMode = async () => {
    await handleButtonAction(postQrCodeMode);
    toast.success("Modo de código QR activado");
  };

  const handleRestartService = async () => {
    await handleButtonAction(() => postRestartService(serviceName));
    toast.success(`Servicio ${serviceName} reiniciado exitosamente`);
  };

  useEffect(() => {
    fetchData();
    intervalRef.current = setInterval(fetchData, 10000);

    return () => clearInterval(intervalRef.current);
  }, []);

  const handleBackdropClick = (event) => {
    if (event.target.classList.contains('modal-backdrop')) {
      onClose();
    }
  };

  return ReactDOM.createPortal(
    <div className="modal-backdrop absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center" onClick={handleBackdropClick}>
      <div className="bg-white p-5 rounded-lg w-full max-w-lg mx-4 h-auto flex flex-col">
        <div className="flex-grow overflow-auto">
          <h2 className="text-lg font-semibold mb-4">Menú de WhatsApp</h2>
          {isLoading ? (
            <p className="text-lg font-semibold">Cargando...</p>
          ) : (
            <table className="min-w-full bg-white">
              <tbody>
                <tr>
                  <td className="border px-4 py-2">Número Conectado</td>
                  <td className="border px-4 py-2">{data.connectedNumber}</td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">Última Conexión</td>
                  <td className="border px-4 py-2">{data.lastConnectedTime}</td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">Enfriamiento</td>
                  <td className="border px-4 py-2">{data.cooldown}</td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">Último Número Hablado</td>
                  <td className="border px-4 py-2">{data.lastSpokenNumber}</td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">Modo</td>
                  <td className="border px-4 py-2">{data.mode}</td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">Número del Teléfono</td>
                  <td className="border px-4 py-2">{data.phoneNumber}</td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">Estado de Conexión</td>
                  <td className="border px-4 py-2">{data.status}</td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">Código QR</td>
                  <td className="border px-4 py-2">
                    {qrImageUrl ? (
                      <img src={qrImageUrl} alt="QR Code" className="inline-block max-h-80" />
                    ) : (
                      "No QR code available"
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          )}
          <div className="flex justify-between mt-4 space-x-2">
            <button
              onClick={handleLogout}
              className={`flex-1 py-2 px-4 rounded-lg text-white transition duration-150 ${
                isButtonDisabled ? "bg-gray-400 cursor-not-allowed" : "bg-blue-500 hover:bg-blue-700"
              }`}
              disabled={isButtonDisabled}
            >
              Logout
            </button>
            <button
              onClick={handlePairingCodeMode}
              className={`flex-1 py-2 px-4 rounded-lg text-white transition duration-150 ${
                isButtonDisabled ? "bg-gray-400 cursor-not-allowed" : "bg-green-500 hover:bg-green-700"
              }`}
              disabled={isButtonDisabled}
            >
              Activar Modo Código de Emparejamiento
            </button>
            <button
              onClick={handleQrCodeMode}
              className={`flex-1 py-2 px-4 rounded-lg text-white transition duration-150 ${
                isButtonDisabled ? "bg-gray-400 cursor-not-allowed" : "bg-purple-500 hover:bg-purple-700"
              }`}
              disabled={isButtonDisabled}
            >
              Activar Modo Código QR
            </button>
          </div>
          <div className="flex justify-between mt-4 space-x-2">
            <select
              value={serviceName}
              onChange={(e) => setServiceName(e.target.value)}
              className="flex-1 py-2 px-4 rounded-lg border-gray-300 focus:border-blue-500 focus:ring-blue-500"
              disabled={isButtonDisabled}
            >
              <option value="backend">Backend</option>
              <option value="whatsapp">WhatsApp</option>
            </select>
            <button
              onClick={handleRestartService}
              className={`flex-1 py-2 px-4 rounded-lg text-white transition duration-150 ${
                isButtonDisabled ? "bg-gray-400 cursor-not-allowed" : "bg-orange-500 hover:bg-orange-700"
              }`}
              disabled={isButtonDisabled}
            >
              Reiniciar Servicio
            </button>
          </div>
        </div>
        <div className="flex justify-end mt-4">
          <button
            onClick={onClose}
            className="py-2 px-6 rounded-lg bg-red-500 text-white hover:bg-red-700 transition duration-150"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>,
    document.getElementById("modal-root")
  );
}

export default WhatsAppMenuModal;
