import React, { useState, useContext, useEffect, useRef } from "react";
import { AiOutlinePlus, AiFillDelete } from "react-icons/ai";
import { LuPanelLeftClose } from "react-icons/lu";
import { SlOptions } from "react-icons/sl";
import { ContextApp } from "../utils/Context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { fetchChatHistory } from "../services/uploadFilesApi";
import FileManagementModal from "./modal/FileManagementModal";
import { deleteTopic } from "../services/topicApi";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import WhatsAppMenuModal from "./modal/WhatsAppMenuModal";
import CreationUserModal from "./modal/CreationUserModal";
import ServiceMenuModal from "./modal/ServiceMenuModal"; 

function LeftNav() {
  const {
    setShowSlide,
    showSlide,
    loadChatHistoryForTopic,
    topics,
    deleteTopicFromList,
    reloadTopicsTrigger,
    setReloadTopicsTrigger,
    resetChat,
    isWaitingForResponse,
    isGuest,
    userRole,
    username,
    setUsername,
  } = useContext(ContextApp);
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showFileManagementModal, setShowFileManagementModal] = useState(false);
  const [showWhatsAppMenuModal, setShowWhatsAppMenuModal] = useState(false);
  const [showCreationUserModal, setShowCreationUserModal] = useState(false);
  const [showServiceMenuModal, setShowServiceMenuModal] = useState(false); 
  const [whatsappShow, setWhatsappShow] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const fetchWhatsappConfig = async () => {
      try {
        const response = await fetch("/configuration.json");
        const data = await response.json();
        setWhatsappShow(data.WHATSAPP_SHOW);
      } catch (error) {
        console.error("Error loading WhatsApp config:", error);
      }
    };

    fetchWhatsappConfig();
  }, []);

  const handleLogout = () => {
    Cookies.remove("accessToken");
    Cookies.remove("expirationDate");
    Cookies.remove("refreshToken");
    Cookies.remove("username");
    setUsername(null);
    navigate("/login");
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const storedUsername = Cookies.get("username");
    if (storedUsername !== username) {
      setUsername(storedUsername);
    }
  }, [username, setUsername]);

  const handleDeleteTopic = async (topicId) => {
    try {
      await deleteTopic(topicId);
      setReloadTopicsTrigger((prev) => prev + 1);
    } catch (error) {
      console.error("Error al eliminar el tópico:", error);
    }
  };

  const handleTopicClick = async (topic) => {
    try {
      const history = await fetchChatHistory(topic);
    } catch (error) {
      console.error(
        "Error al intentar cargar el historial del chat para el tópico:",
        topic,
        error
      );
    }
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <div key={reloadTopicsTrigger}>
      <div
        className={
          !showSlide
            ? "flex flex-col h-screen COLOUR_LOGIN_BACKGROUND_AND_LEFT_BAR w-[300px] border-r lg:flex items-center justify-between text-white translate-x-0"
            : "hidden"
        }
      >
        {showFileManagementModal && (
          <FileManagementModal
            onClose={() => setShowFileManagementModal(false)}
          />
        )}
        {showWhatsAppMenuModal && (
          <WhatsAppMenuModal onClose={() => setShowWhatsAppMenuModal(false)} />
        )}
        {showCreationUserModal && (
          <CreationUserModal onClose={() => setShowCreationUserModal(false)} />
        )}
        {showServiceMenuModal && (
          <ServiceMenuModal onClose={() => setShowServiceMenuModal(false)} />
        )}

        <div className="flex flex-col items-center w-full h-full">
          <img
            src="./static/media/LogoCompany.png"
            alt="Icono del Sidebar"
            className="max-w-full max-h-40 w-auto h-auto mt-4 mb-2"
          />
          <div className="w-full px-4 mt-5 flex justify-between items-center">
            <span
              className={`border border-white rounded w-[calc(100%-4rem)] py-2 text-xs flex gap-1 items-center justify-center cursor-pointer ${
                isWaitingForResponse ? "opacity-50 pointer-events-none" : ""
              }`}
              onClick={resetChat}
            >
              <AiOutlinePlus fontSize={18} />
              Chat Nuevo
            </span>
            <span
              className="border border-white rounded px-3 py-[9px] flex items-center justify-center cursor-pointer"
              title="Close sidebar"
              onClick={() => setShowSlide(!showSlide)}
            >
              <LuPanelLeftClose />
            </span>
          </div>
          <div className="flex-1 px-4 mt-5 overflow-y-auto max-h-[calc(100vh-200px)]">
            {topics.map((topic, index) => (
              <div
                key={index}
                className={`rounded w-full py-3 px-2 text-xs my-2 flex items-center justify-between cursor-pointer hover:bg-gray-800 transition-all duration-300 overflow-hidden relative ${
                  isWaitingForResponse ? "opacity-50 pointer-events-none" : ""
                }`}
                onClick={() => loadChatHistoryForTopic(topic.id)}
              >
                <span
                  className="flex-1 truncate hover:text-red-500"
                  style={{
                    transition: "color 0.3s ease",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "150px", 
                  }}
                >
                  {truncateText(topic.name, 15)}
                </span>
                <AiFillDelete
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteTopicFromList(topic.id);
                  }}
                  className="text-red-500 cursor-pointer ml-4 hover:text-gray-900"
                  style={{ transition: "color 0.3s ease" }}
                  onMouseEnter={(e) =>
                    e.currentTarget.parentNode.firstChild.classList.add(
                      "text-red-500"
                    )
                  }
                  onMouseLeave={(e) =>
                    e.currentTarget.parentNode.firstChild.classList.remove(
                      "text-red-500"
                    )
                  }
                />
              </div>
            ))}
          </div>
          <div className="w-full FOOTER_LEFT_BAR border-t border-gray-600 flex flex-col gap-2 items-center justify-center py-5">
            <span className="rounded w-full py-2 px-2 mt-5 text-xs flex gap-1 items-center justify-between cursor-pointer transition-all duration-300">
              {isGuest ? (
                <>
                  <span className="flex gap-2 ml-3 mr-1 items-center justify-center text-sm font-bold text-gray-200">
                    Invitado
                  </span>
                  <button
                    className="rounded-md px-4 py-2 text-sm font-medium bg-gray-600 hover:bg-gray-700 text-white uppercase cursor-pointer transition-colors duration-300"
                    onClick={() => navigate("/login")}
                  >
                    Iniciar Sesión
                  </button>
                </>
              ) : (
                <>
                  <span className="flex gap-2 ml-3 mr-1 items-center justify-center text-sm font-bold text-white">
                    <FontAwesomeIcon icon={faUser} />
                    {username || "Usuario"}
                  </span>
                  <span
                    className="rounded-md px-4 py-2 text-sm font-medium hover:bg-gray-800 uppercase text-gray-500 cursor-pointer"
                    onClick={(event) => {
                      event.stopPropagation();
                      setShowDropdown(!showDropdown);
                    }}
                  >
                    <SlOptions />
                  </span>
                </>
              )}
            </span>
            {showDropdown && !isGuest && (
              <div
                ref={dropdownRef}
                className="absolute bottom-[60px] right-0 translate-x-[20%] w-48 bg-white text-black rounded shadow-md z-10"
              >
                <ul>
                  {userRole === "admin" && (
                    <>
                      <li
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                          setShowCreationUserModal(true);
                          setShowDropdown(false);
                        }}
                      >
                        Creacion de Usuario
                      </li>
                      {whatsappShow && (
                        <li
                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() => {
                            setShowWhatsAppMenuModal(true);
                            setShowDropdown(false);
                          }}
                        >
                          Menú de WhatsApp
                        </li>
                      )}
                      <li
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                          setShowServiceMenuModal(true);
                          setShowDropdown(false);
                        }}
                      >
                        Menú de Servicio
                      </li>
                      <li
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                          setShowFileManagementModal(true);
                          setShowDropdown(false);
                        }}
                      >
                        Administración de Archivos
                      </li>
                    </>
                  )}
                  <li
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={handleLogout}
                  >
                    Cerrar Sesión
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeftNav;
